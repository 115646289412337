import React from 'react';
import { ReactComponent as IconFacebook } from './assets/icons/facebook.svg';
import { ReactComponent as IconTwitter } from './assets/icons/twitter.svg';
import './App.css';
import Logo from './assets/white-prilogin.svg';
import IconLogo from './assets/white-icon-prilogin.svg';
import BlackIconLogo from './assets/white-icon-prilogin.svg';
import Favicon from 'react-favicon';
class App extends React.Component {
  render = () => {
    return (
      <div className="card">
        <Favicon url={[BlackIconLogo]} />
        <div className="header">
          <div className="logo">
            <a href="/">
              <img alt="PriLogin Logo" src={Logo} width={200} />
            </a>
          </div>
          <div className="social">
            <a href="https://facebook.com" title="Facebook" target="_blank" rel="noopener noreferrer">
              <IconFacebook className="icon" />
            </a>
            <a href="https://twitter.com" title="Twitter" target="_blank" rel="noopener noreferrer">
              <IconTwitter className="icon" />
            </a>
          </div>
        </div>
        <div className="content">
          <div className="title-holder">
            <h1>Collaborate with confidence, login safely with PriLogin</h1>
            <p>
              <strong style={{ fontWeight: '700', color: '#fff' }}>Prilogin</strong> is Antidetect Browser. Securely access accounts with
              ease for teams more login, more accounts.
            </p>
          </div>
          <a href="mailto:subscribe@prilogin.com">
            <div className="cta">Send us an email</div>
          </a>
        </div>
        <div className="footer">
          <span>
            made by{' '}
            <a className="underlined" href="https://prilogin.com" target="_blank" rel="noopener noreferrer">
              <img alt="PriLogin Logo" src={IconLogo} width={12} />
            </a>
          </span>
        </div>
      </div>
    );
  };
}

export default App;
